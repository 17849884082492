<template>
  <div class="container bg-white mt-1">
    <div class="p-48">
      <div v-if="!loading">
        <div class="border-bottom mb-3 d-flex justify-content-end py-3">
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"
            @click="goToList" class="cursor-pointer">
            <path d="M6 18.4998L17.9998 6.5" stroke="#292D32" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
            <path d="M17.9998 18.4998L6 6.5" stroke="#292D32" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </div>

        <div class="row">
          <div class="col-lg-6 col-xl-7">
            <div class="d-flex justify-content-between">
              <p class="fs-24 fw-600 max-line-2">{{ getCircleMultipleLang().name }}</p>
            </div>
            <div class="d-flex mb-3">
              <div class="icinfo">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M14 17H4V19H14V17ZM20 9H4V11H20V9ZM4 15H20V13H4V15ZM4 5V7H20V5H4Z" fill="#84818A" />
                </svg>
              </div>
              <p class="white-s-pre">{{ getCircleMultipleLang().location || getCircleMultipleLang().description }}</p>
            </div>
          </div>
          <div class="col-lg-4 ml-auto">
            <div class="open-chat-att" @click="goToAttachment" v-if="isMember || isAdmin">
              <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"
                class="mr-2">
                <path d="M8 16H40V36H8V16Z" fill="#20419B" />
                <path
                  d="M40 12H24L20 8H8C5.8 8 4.02 9.8 4.02 12L4 36C4 38.2 5.8 40 8 40H40C42.2 40 44 38.2 44 36V16C44 13.8 42.2 12 40 12ZM40 36H8V16H40V36Z"
                  fill="#20419B" />
              </svg>
              <span class="txt-black fs-16 fw-500">{{ $t('home_page.lbl_org_resource') }}</span>
            </div>
            <!-- <div
              class="open-chat-att justify-content-between"
              @click="goToChat"
              v-if="(isMember || isAdmin)"
              :class="[
                unread_message_count ? 'has-unread-chat' : '',
                $conversationsClient &&
                circle &&
                circle.twilio_conversation &&
                circle.twilio_conversation.twilio_conv_id
                  ? ''
                  : 'disabled',
              ]"
            >
              <div class="d-flex align-items-center">
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="mr-2"
                >
                  <path d="M30 22V8H8V24.34L10.34 22H12H30Z" fill="#20419B" />
                  <path
                    d="M32 26C33.1 26 34 25.1 34 24V6C34 4.9 33.1 4 32 4H6C4.9 4 4 4.9 4 6V34L12 26H32ZM8 24.34V8H30V22H10.34L8 24.34ZM44 14C44 12.9 43.1 12 42 12H38V30H12V34C12 35.1 12.9 36 14 36H36L44 44V14Z"
                    fill="#20419B"
                  />
                </svg>

                <span
                  :class="
                    unread_message_count
                      ? 'txt-pri fs-20 fw-700'
                      : 'txt-black fs-16 fw-500'
                  "
                >Chat</span>
              </div>
              <p
                class="mb-0 txt-pri fs-20 fw-700"
                v-if="unread_message_count"
              >+{{ unread_message_count }} {{$t('circle_feature.lbl_new')}}</p>
              <div class="red-dot-new" v-if="unread_message_count">
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="8.5" cy="8.5" r="8.5" fill="#D92D20" />
                </svg>
              </div>
            </div>-->

            <!-- LIVE STREAM -->
            <!-- <div
              v-if="
                (isMember || isAdmin) &&
                circle &&
                (circle.type === CIRCLE_TYPE.LIVE || circle.live_room_id)
              "
              class="open-chat-att"
              @click="handleClickToRoom"
            >
              <div class="d-flex align-items-center">
                <img
                  class="mr-2"
                  :src="liveStream"
                  :alt="circle && circle.name"
                  width="32"
                  height="32"
                />
                <div>
                  <span
                    class="inline-block livestream-redot mb-0"
                    v-if="circle && circle.is_live_stream === 2"
                  >Đang trực tiếp</span>
                  <span class="block txt-black fs-16 fw-500">
                    {{
                    (user_100ms_role === "presenter" || user_100ms_role === "broadcaster") ? $t("circle_feature.lbl_circle_live") : circle && circle.is_live_stream === 2 ? $t("circle_feature.lbl_circle_live_watch_now") : $t("circle_feature.lbl_circle_live_watch")
                    }}
                  </span>
                </div>
              </div>
            </div>
            <div
              v-else-if="(isMember || isAdmin) && circle && !circle.live_room_id"
              class="open-chat-att"
              :class="{'disabled': !isAdmin}"
              @click="createLiveRoom(circle.id)"
            >
              <div class="d-flex align-items-center">
                <img
                  class="mr-2"
                  :src="liveStream"
                  :alt="circle && circle.name"
                  width="32"
                  height="32"
                />
                <div>
                  <span
                    class="block txt-black fs-16 fw-500"
                  >{{ $t("circle_feature.lbl_circle_create_live_room") }}</span>
                </div>
              </div>
            </div>-->

            <!-- CONFERENCE -->
            <div
              v-if="conferenceRoomStatus && circle && circle.type === CIRCLE_TYPE.NORMAL && circle.one_hundred_ms_conf_id"
              class="open-chat-att" :class="{
                'opacity-50': hasEndEvent
              }" @click="handleClickConference">
              <div class="d-flex align-items-center">
                <div class="w-9 h-9 mx-2">
                  <svg viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_123_32)">
                      <path
                        d="M118.945 103.132L77.7974 1.53337C76.9659 0.561005 75.7507 0 74.4715 0H15.2866C6.8651 0 0 6.87083 0 15.3178V234.682C0 243.129 6.8651 250 15.2866 250H80.7822C83.1914 250 85.1528 248.039 85.1528 245.624V229.911C85.1528 224.648 86.6026 219.509 89.3422 215.037L93.3824 203.401H82.6051C72.1689 203.401 62.9266 196.727 59.6219 186.795C57.3087 179.818 58.2361 176.28 62.1804 170.077C66.1139 163.88 72.5526 163.863 79.8441 163.025L98.0623 160.925L85.6433 125.759C84.9184 123.996 84.993 122.003 85.8458 120.299C86.7092 118.594 88.2657 117.349 90.1098 116.885L116.685 110.222C118.817 109.688 119.564 108.005 119.788 107.305C120.011 106.61 120.374 104.805 118.945 103.132Z"
                        fill="#20419B" />
                      <path
                        d="M115.854 151.285C113.682 149.113 110.162 149.113 107.99 151.285C105.827 153.457 105.827 156.977 107.999 159.149C113.944 165.099 117.229 173.013 117.229 181.429C117.229 189.84 113.944 197.754 107.999 203.704C105.827 205.876 105.827 209.396 107.99 211.568C109.076 212.654 110.505 213.197 111.926 213.197C113.347 213.197 114.768 212.654 115.854 211.568C123.908 203.518 128.342 192.813 128.342 181.429C128.342 170.04 123.908 159.334 115.854 151.285Z"
                        fill="#20419B" />
                      <path
                        d="M135.509 131.629C133.338 129.457 129.817 129.457 127.645 131.629C125.483 133.801 125.483 137.321 127.654 139.493C138.858 150.692 145.029 165.587 145.029 181.428C145.029 197.269 138.858 212.16 127.654 223.363C125.483 225.535 125.483 229.056 127.654 231.228C128.731 232.313 130.161 232.856 131.582 232.856C133.003 232.856 134.423 232.313 135.509 231.228C148.821 217.925 156.142 200.238 156.142 181.428C156.142 162.614 148.821 144.932 135.509 131.629Z"
                        fill="#20419B" />
                      <path
                        d="M227.726 140.479H215.062V137.313C215.062 136.473 214.728 135.668 214.135 135.074C213.541 134.481 212.736 134.147 211.896 134.147C211.056 134.147 210.251 134.481 209.657 135.074C209.064 135.668 208.73 136.473 208.73 137.313V140.479H196.066C195.226 140.479 194.421 140.813 193.827 141.406C193.233 142 192.9 142.805 192.9 143.645C192.9 144.485 193.233 145.29 193.827 145.884C194.421 146.477 195.226 146.811 196.066 146.811H217.247C216.079 150.156 214.379 153.291 212.213 156.094C210.749 154.631 209.402 153.056 208.185 151.383C207.717 150.689 206.993 150.21 206.172 150.049C205.351 149.887 204.499 150.058 203.803 150.523C203.108 150.988 202.624 151.709 202.459 152.529C202.293 153.35 202.459 154.202 202.92 154.9C204.378 156.955 206.01 158.88 207.799 160.653C204.969 163.011 201.72 164.815 198.222 165.969C197.424 166.234 196.765 166.805 196.388 167.557C196.012 168.309 195.949 169.179 196.215 169.977C196.48 170.775 197.051 171.434 197.803 171.811C198.555 172.187 199.425 172.249 200.223 171.984C204.76 170.498 208.951 168.112 212.545 164.968C215.818 167.632 219.373 169.931 223.145 171.823C223.896 172.198 224.766 172.259 225.563 171.993C225.958 171.862 226.322 171.654 226.636 171.381C226.951 171.108 227.208 170.776 227.394 170.404C227.58 170.032 227.69 169.627 227.72 169.212C227.749 168.797 227.696 168.381 227.564 167.986C227.433 167.592 227.225 167.227 226.952 166.913C226.679 166.599 226.347 166.341 225.975 166.155C222.776 164.541 219.755 162.595 216.962 160.349C220.12 156.325 222.47 151.728 223.883 146.811H227.726C228.566 146.811 229.371 146.477 229.965 145.884C230.559 145.29 230.892 144.485 230.892 143.645C230.892 142.805 230.559 142 229.965 141.406C229.371 140.813 228.566 140.479 227.726 140.479Z"
                        fill="#20419B" />
                      <path
                        d="M240.39 121.483H183.401C180.882 121.483 178.466 122.484 176.685 124.265C174.904 126.046 173.903 128.462 173.903 130.981V175.305C173.903 177.824 174.904 180.24 176.685 182.021C178.466 183.803 180.882 184.803 183.401 184.803H186.567V197.467C186.567 198.033 186.719 198.587 187.006 199.074C187.292 199.561 187.704 199.963 188.199 200.237C188.693 200.511 189.252 200.647 189.817 200.632C190.381 200.618 190.932 200.452 191.411 200.152L215.97 184.803H240.39C242.909 184.803 245.325 183.803 247.106 182.021C248.887 180.24 249.888 177.824 249.888 175.305V130.981C249.888 128.462 248.887 126.046 247.106 124.265C245.325 122.484 242.909 121.483 240.39 121.483ZM243.556 175.305C243.556 176.145 243.222 176.95 242.628 177.544C242.035 178.138 241.229 178.471 240.39 178.471H215.062C214.468 178.471 213.887 178.638 213.384 178.953L192.899 191.769V181.637C192.899 180.798 192.566 179.992 191.972 179.399C191.378 178.805 190.573 178.471 189.733 178.471H183.401C182.562 178.471 181.756 178.138 181.163 177.544C180.569 176.95 180.235 176.145 180.235 175.305V130.981C180.235 130.141 180.569 129.336 181.163 128.742C181.756 128.149 182.562 127.815 183.401 127.815H240.39C241.229 127.815 242.035 128.149 242.628 128.742C243.222 129.336 243.556 130.141 243.556 130.981V175.305Z"
                        fill="#20419B" />
                      <path
                        d="M146.498 41.9097H203.487C206.006 41.9097 208.422 42.9104 210.203 44.6916C211.984 46.4728 212.985 48.8887 212.985 51.4077V95.732C212.985 98.2511 211.984 100.667 210.203 102.448C208.422 104.229 206.006 105.23 203.487 105.23H179.067L154.508 120.579C154.029 120.879 153.479 121.044 152.914 121.059C152.349 121.074 151.79 120.937 151.296 120.663C150.801 120.389 150.389 119.988 150.103 119.501C149.816 119.014 149.664 118.459 149.664 117.894V105.23H146.498C143.979 105.23 141.564 104.229 139.782 102.448C138.001 100.667 137 98.2511 137 95.732V51.4077C137 48.8887 138.001 46.4728 139.782 44.6916C141.564 42.9104 143.979 41.9097 146.498 41.9097ZM143.332 95.732C143.332 96.5717 143.666 97.377 144.26 97.9708C144.853 98.5645 145.659 98.8981 146.498 98.8981H152.83C153.67 98.8981 154.475 99.2316 155.069 99.8254C155.663 100.419 155.996 101.224 155.996 102.064V112.195L176.481 99.392C176.982 99.073 177.564 98.9018 178.159 98.8981H203.487C204.326 98.8981 205.132 98.5645 205.726 97.9708C206.319 97.377 206.653 96.5717 206.653 95.732V51.4077C206.653 50.5681 206.319 49.7628 205.726 49.169C205.132 48.5753 204.326 48.2417 203.487 48.2417H146.498C145.659 48.2417 144.853 48.5753 144.26 49.169C143.666 49.7628 143.332 50.5681 143.332 51.4077V95.732Z"
                        fill="#20419B" />
                      <path
                        d="M177.841 56.3247C177.548 55.834 177.133 55.4277 176.637 55.1456C176.14 54.8634 175.578 54.7151 175.007 54.7151C174.436 54.7151 173.875 54.8634 173.378 55.1456C172.881 55.4277 172.466 55.834 172.174 56.3247L156.344 87.9849C156.158 88.357 156.047 88.7621 156.018 89.177C155.988 89.5919 156.041 90.0085 156.173 90.403C156.304 90.7976 156.513 91.1623 156.785 91.4764C157.058 91.7905 157.39 92.0478 157.762 92.2337C158.134 92.4195 158.539 92.5303 158.954 92.5596C159.369 92.5889 159.786 92.5362 160.18 92.4044C160.575 92.2727 160.939 92.0646 161.253 91.7919C161.568 91.5193 161.825 91.1874 162.011 90.8153L174.991 64.8191L180.95 76.736H174.991C174.152 76.736 173.346 77.0696 172.753 77.6633C172.159 78.2571 171.825 79.0623 171.825 79.902C171.825 80.7417 172.159 81.547 172.753 82.1407C173.346 82.7345 174.152 83.068 174.991 83.068H184.116L187.988 90.8153C188.174 91.1874 188.431 91.5193 188.745 91.7919C189.059 92.0646 189.424 92.2727 189.819 92.4044C190.213 92.5362 190.63 92.5889 191.045 92.5596C191.46 92.5303 191.865 92.4195 192.237 92.2337C192.609 92.0478 192.941 91.7905 193.213 91.4764C193.486 91.1623 193.694 90.7976 193.826 90.403C193.958 90.0085 194.01 89.5919 193.981 89.177C193.952 88.7621 193.841 88.357 193.655 87.9849L177.841 56.3247Z"
                        fill="#20419B" />
                    </g>
                    <defs>
                      <clipPath id="clip0_123_32">
                        <rect width="250" height="250" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div>
                  <span class="inline-block livestream-redot mb-0" v-if="circle && circle.is_live_stream === 2">Đang
                    trực tiếp</span>
                  <span class="block txt-black fs-16 fw-500">{{ $t('circle_feature.lbl_circle_multi_lang_meet')
                    }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12" v-if="isPublicVideo">
            <div class="flex items-center mb-2">
              <div class="mr-2">
                <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
                  stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                  <path
                    d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z" />
                  <polygon points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02" />
                </svg>
              </div>
              <div class="fs-14 fw-600">Video</div>
            </div>
            <div class="mb-3" :class="{ 'max-w-5xl': !zoomVideo }">
              <video-player class="video-player-box" ref="videoPlayer" :options="playerOptions"
                @play="onPlayerPlay($event)" @pause="onPlayerPause($event)" @ended="onPlayerEnded($event)"
                @loadeddata="onPlayerLoadeddata($event)" @waiting="onPlayerWaiting($event)"
                @playing="onPlayerPlaying($event)" @timeupdate="onPlayerTimeupdate($event)"
                @canplay="onPlayerCanplay($event)" @canplaythrough="onPlayerCanplaythrough($event)"
                @statechanged="playerStateChanged($event)" @ready="playerReadied"></video-player>
              <div class="bg-gray-50 flex items-center">
                <div class="bg-gray-100 hover:bg-gray-200 ml-auto cursor-pointer p-2 border transition-all duration-200"
                  @click.stop="toggleZoomVideo">
                  <span class="inline-block w-6 h-6 mr-2">
                    <svg viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <mask id="path-1-inside-1_1_2" fill="white">
                        <path d="M0 41C0 38.7909 1.79086 37 4 37H38V77H4C1.79086 77 0 75.2091 0 73V41Z" />
                      </mask>
                      <path d="M0 41C0 38.7909 1.79086 37 4 37H38V77H4C1.79086 77 0 75.2091 0 73V41Z"
                        stroke="currentColor" stroke-width="16" mask="url(#path-1-inside-1_1_2)" />
                      <mask id="path-2-inside-2_1_2" fill="white">
                        <path
                          d="M30 26C30 23.7909 31.7909 22 34 22H96C98.2091 22 100 23.7909 100 26V73C100 75.2091 98.2091 77 96 77H30V26Z" />
                      </mask>
                      <path
                        d="M30 26C30 23.7909 31.7909 22 34 22H96C98.2091 22 100 23.7909 100 26V73C100 75.2091 98.2091 77 96 77H30V26Z"
                        stroke="currentColor" stroke-width="16" mask="url(#path-2-inside-2_1_2)" />
                    </svg>
                  </span>
                  <span v-if="!zoomVideo">{{ $t('Phóng lớn') }}</span>
                  <span v-else>{{ $t('Thu nhỏ') }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="d-flex w-full mb-3">
              <div class="icinfo">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12.5947 18.2565V13.6353M12.5947 12.6891C12.8067 10.038 11.3857 5.02198 4.00492 6.16728C3.89887 8.34122 5.46835 12.6891 12.5947 12.6891ZM12.5947 12.6891C13.7081 11.5226 15.723 8.56014 20.5 10C20.0654 12.0378 18.3162 14.7488 12.5947 13.6353M12.5947 12.6891V13.6353"
                    stroke="#84818A" stroke-width="2" />
                  <rect x="3" y="18" width="17" height="2" fill="#84818A" />
                </svg>
              </div>
              <div class="flex-1">
                <p class="fs-14 fw-600 mb-1 mt-1">{{ $t('conference.lbl_chair') }}</p>
                <div class="flex flex-col lg:flex-row flex-wrap -mx-2">
                  <template v-for="seed in membersData.data">
                    <div class="lg:w-1/3 px-2 mb-2" :key="seed.id" v-if="seed.user_info && seed.user_info.doctor_infor">
                      <div :set="seedInfo = seed.user_info" class="d-flex align-items-center cursor-pointer"
                        @click="goToProfile(seed.user_info)">
                        <div style="min-width: 40px">
                          <!-- <span
                            v-if="seedInfo.avatar"
                            class="avatar avatar-sm avatar-rounded"
                            :style="
                          'background-image: url(' +
                          getImageURL(seedInfo.avatar) +
                          ')'
                        "
                          ></span>-->
                          <ImageBox v-if="seedInfo.avatar"
                            class="avatar avatar-sm avatar-rounded object-cover object-top border" avatar
                            :source="getImageURL(seedInfo.avatar)"
                            :alt="getMultilangValue(seedInfo.multilang) ? getMultilangValue(seedInfo.multilang).name : seedInfo.name" />
                          <span v-if="!seedInfo || !seedInfo.avatar"
                            class="avatar avatar-sm avatar-rounded bg-pri text-white">
                            {{
                              seedInfo.name.charAt(0) ||
                              seedInfo.email.charAt(0)
                            }}
                          </span>
                        </div>
                        <div class="flex-1">
                          <p class="fs-14 fw-600 txt-black mb-1">{{ getMultilangValue(seedInfo.multilang) ?
                            getMultilangValue(seedInfo.multilang).name : seedInfo.name }}</p>
                          <p class="fs-12 fw-500 txt-grey-600 mb-0">{{ getMultilangValue(seedInfo.multilang) ?
                            getMultilangValue(seedInfo.multilang).job_title : (seedInfo.doctor_infor &&
                              seedInfo.doctor_infor.chuc_vu) }}</p>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <CircleSession v-if="circle" class="col-12" :isAdmin="isAdmin" :seedMember="circle && circle.seed"
            @setLastSchedule="setLastSchedule" />
          <CircleMembers />
        </div>
      </div>

      <div class="loading-wr" v-if="loading">
        <div class="skeleton-line" v-for="i in 15" :key="i + 'loading'"></div>
      </div>
    </div>
  </div>
</template>

<script>
import 'videojs-resolution-switcher'
import 'videojs-resolution-switcher/lib/videojs-resolution-switcher.css'
import 'videojs-watermark'

import ConferenceImg from '@/assets/images/conference_room.png'
import liveStream from '@/assets/images/livestream.png'
import ImageBox from '@/components/Common/Image.vue'
import { LANGUAGE_CODES_FOR_DISEASE } from '@/lang'
import CircleMembers from '@/pages/Conference/Circle/CircleMembers.vue'
import CircleSession from '@/pages/Conference/Circle/CircleSession'
import appUtils from '@/utils/appUtils'
import { CIRCLE_TYPE, CONFERENCE_MEMBERS_TYPE } from '@/utils/constants'
import { videoPlayer } from 'vue-video-player'
// import { v4 as uuid } from 'uuid'

export default {
  name: 'ConferenceCircleDetail',
  components: {
    CircleSession,
    CircleMembers,
    ImageBox,
    videoPlayer
  },
  data () {
    const conferenceRoomStatus = true
    const playerSources = [
      // {
      //   src: 'https://vjs.zencdn.net/v/oceans.mp4?SD',
      //   type: 'video/mp4',
      //   label: 'SD',
      //   res: 360
      // },
      // {
      //   src: 'https://vjs.zencdn.net/v/oceans.mp4?HD',
      //   type: 'video/mp4',
      //   label: 'HD',
      //   res: 720
      // }
      // {
      //   type: 'video/mp4',
      //   src: 'http://7xkwa7.media1.z0.glb.clouddn.com/sample_video_H',
      //   label: '1080P',
      //   res: 1
      // },
      // {
      //   type: 'video/mp4',
      //   src:
      //     'http://221.11.100.42/7xkwa7.media1.z0.glb.clouddn.com/sample_video_M?wsiphost=local',
      //   label: '720P',
      //   res: 2
      // },
      // {
      //   type: 'video/mp4',
      //   src: 'http://7xkwa7.media1.z0.glb.clouddn.com/sample_video_L',
      //   label: '360P',
      //   res: 3
      // }
    ]

    return {
      circle: null,
      loading: false,
      circle_member: null,
      member_loading: false,
      user_id: null,
      unread_message_count: 0,
      activeConversation: null,
      updating_info: false,
      form_edit: {
        name: '',
        description: '',
        logo: null,
        background: null
      },
      liveStream,
      CIRCLE_TYPE,
      ConferenceImg,
      conferenceRoomStatus,
      ctaRefreshRoles: 0,
      selectedFeature: 'conference',
      membersData: {},
      user_100ms_role: 'guest',
      appUtils,
      lastSchedule: null,
      zoomVideo: false,
      playerSources,
      playerOptions: {
        muted: false,
        language: this.$lang,
        plugins: {
          videoJsResolutionSwitcher: {
            ui: true,
            default: 3,
            dynamicLabel: true
          }
          // watermark: {
          //   image: 'https://hodo.app/hodologo_256x256.png',
          //   url: 'https://hodo.com.vn'
          // }
        },
        playbackRate: 1,
        playbackRates: [0.7, 1.0, 1.5, 2.0]
        // sources: playerSources,
        // poster: '/static/images/author-3.jpg'
      }
    }
  },
  computed: {
    isMember () {
      return !!this.circle_member && this.circle_member.status === 2
    },
    isAdmin () {
      return (
        (!!this.isMember && this.circle_member.role === 1) ||
        this.circle?.created_by === this.user_id
      )
    },
    lang () {
      return LANGUAGE_CODES_FOR_DISEASE[this.$lang.toUpperCase()]
    },
    hasEndEvent () {
      console.log(this.lastSchedule)
      if (!this.lastSchedule) {
        return true
      }
      const nowDate = Date.now()
      return nowDate > this.lastSchedule?.schedule_date
    },
    player () {
      return this.$refs.videoPlayer.player
    },
    isPublicVideo () {
      return (
        this.circle?.live_room_recording_url &&
        this.circle?.is_show_url_record === 2 &&
        appUtils.isJSON(this.circle?.live_room_recording_url)
      )
    }
  },
  async mounted () {
    this.user_id = appUtils.getLocalUser().id
    await this.prepare()
    this.get100msRole()
    this.getConferenceCircleMembers()
    // console.log('this is current player instance object', this.player)
  },
  methods: {
    handleClickConference () {
      const self = this
      self.ctaRefreshRoles++
      self.selectedFeature = 'conference'

      if (self.hasEndEvent) {
        self
          .$alert(
            self.$t(
              'Phiên hội thảo đã kết thúc (hoặc) tạm thời bạn không thể vào phòng.'
            ),
            self.$t('Thông báo'),
            {
              showConfirmButton: false,
              closeOnClickModal: true
            }
          )
          .catch(() => { })
        return
      }
      // if (self.isAdmin || self.isMyHasAssignRole) {
      self.goToMultiLangConference()
    },
    goToList () {
      // this.$router.push({ name: 'WorkspaceConference' })
      this.$router.go(-1)
    },
    async prepare () {
      try {
        this.loading = true
        await this.showCircle()
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    getImageURL (a) {
      return appUtils.getImageURL(a)
    },
    async showCircle () {
      let self = this
      if (!this.$route.params.id) return
      let r = await this.$rf
        .getRequest('DoctorRequest')
        .showCircle(this.$route.params.id)
        .then(r => {
          const circleType = r.data?.type
          if (circleType === CIRCLE_TYPE.LIVE) {
            self.$router.replace({
              path: '/live-circle/' + self.$route.params.id
            })
            return
          }
          self.circle = { ...r.data }
          // self.circle.type = 2
          self.form_edit = {
            name: r.data?.name,
            description: r.data?.description,
            logo: r.data?.logo,
            background: r.data?.background
          }
          return r
        })
        .catch(e => {
          console.log(e)
          self.$toast.open({
            message: this.$t('circle_feature.lbl_permission_msg'),
            type: 'warning'
          })
          self.$router.push({ name: 'CircleList' })
          return false
        })
      return r?.data
    },
    showModal (show, modalRef) {
      window.$(modalRef).modal(show ? 'show' : 'hide')
    },
    refresh () {
      this.showModal(false, '#modalAddCMem')
    },
    goToAttachment () {
      this.$router.push({
        name: 'WorkspaceConferenceDocument',
        params: { id: this.$route.params.id }
      })
    },
    async goToMultiLangConference () {
      if (!this.circle.one_hundred_ms_conf_id) return ''

      const { id, one_hundred_ms_conf_id } = this.circle
      await this.get100msRole().then(() => {
        let token = appUtils.getLocalToken()
        // let dm = 'http://localhost:3000'
        let dm = ''
        if (process.env.NODE_ENV === 'development') {
          dm = 'https://dev.live.hodo.app'
        } else {
          dm = 'https://live.hodo.app'
        }

        const roleObj = appUtils.isJSON(this.user_100ms_role)
          ? JSON.parse(this.user_100ms_role)
          : {
            [this.selectedFeature]: this.user_100ms_role
          }
        const role = roleObj[this.selectedFeature]
        const routeRaw = `${dm}/redirect.html?token=${token}&room_id=${one_hundred_ms_conf_id}&circle_id=${id}&type=conference&role=${role ||
          'guest'}&lang=${this.$lang}`

        console.log(routeRaw)

        // let linkElement = document.createElement('a')
        // const linkId = `conf_${one_hundred_ms_conf_id}`
        // linkElement.id = linkId
        // linkElement.href = routeRaw
        // window.document.body.appendChild(linkElement)
        //
        // const linkEl = document.getElementById(linkId)
        // linkEl.setAttribute('rel', 'noopener noreferrer')
        // !appUtils.checkIsMobile() && linkEl.setAttribute('target', '_blank')
        // linkEl.click()
        // linkEl.remove()

        setTimeout(() => {
          window.open(
            routeRaw,
            // !appUtils.checkIsMobile() &&
            '_blank',
            'noopener noreferrer'
          )
        })
      })
    },
    handleClickToRoom () {
      this.ctaRefreshRoles++
      this.selectedFeature = 'livestream'

      // if (this.isAdmin || this.isMyHasAssignRole) {
      if (this.isAdmin) {
        this.showModal(true, '#modalAssignRole')
      } else {
        this.goToStream()
      }
    },
    async uploadFiles (file, circle) {
      const params = {
        file,
        FolderTarget: `circle`,
        IdTarget: circle.id
      }
      const formData = new FormData()

      for (const key in params) {
        formData.append(key, params[key])
      }

      const res = await this.$rf
        .getRequest('DoctorRequest')
        .uploadFileTenant(formData)
      return res.data.fileUrl
    },
    goToProfile (data) {
      const doctorId = data?.doctor_infor?.id
      if (!doctorId) return
      this.$router
        .push({ name: 'DoctorProfile', params: { id: doctorId } })
        .catch(() => { })
    },
    async getConferenceCircleMembers () {
      if (!this.$route.params.id) return

      try {
        const circle_id = this.$route.params.id
        const params = {
          role: CONFERENCE_MEMBERS_TYPE.chair,
          page_num: 1,
          page_size: 1000
        }

        const response = await this.$rf
          .getRequest('ConferenceRequest')
          .getConferenceCircleMembers(circle_id, params)

        const responseData = response.data || {}
        this.membersData = responseData
      } catch (error) {
        console.log(error)
      }
    },
    async get100msRole () {
      await this.$rf
        .getRequest('DoctorRequest')
        .checkMyRoleConferenceRoom({
          circle_id: this.circle.id,
          user_id: this.user_id
        })
        .then(res => {
          this.user_100ms_role =
            res.data?.one_hundred_ms_role ||
            JSON.stringify({
              conference: 'guest',
              livestream: 'viewer'
            })
        })
        .catch(e => {
          console.log(e)
          this.$toast.open({
            message: e,
            type: 'error'
          })
        })
    },
    getCircleMultipleLang () {
      const langValue = this.lang
      const langData =
        this.circle?.circle_multilanguages?.find(
          item => item?.lang === langValue
        ) || this.circle || {}
      return langData
    },
    getMultilangValue (data) {
      if (!data) return ''
      return data.find(item => (item.Lang || item.lang) === this.lang)
    },
    setLastSchedule (value) {
      if (!value) return
      this.lastSchedule = { ...value }
    },
    onPlayerPlay (player) { },
    onPlayerPause (player) { },
    onPlayerEnded (player) { },
    onPlayerTimeupdate (player) { },
    onPlayerLoadeddata (player) { },
    onPlayerWaiting (player) { },
    onPlayerPlaying (player) { },
    onPlayerCanplay (player) { },
    onPlayerCanplaythrough (player) { },
    playerStateChanged (playerCurrentState) { },
    playerReadied (player) {
      if (player.watermark && this.circle?.live_room_recording_url) {
        player.watermark({
          image: this.circle?.logo || 'https://hodo.app/hodologo_256x256.png'
        })
      }
      if (player.updateSrc && this.circle?.live_room_recording_url) {
        const sourcesData = appUtils.isJSON(
          this.circle?.live_room_recording_url
        )
          ? JSON.parse(this.circle?.live_room_recording_url)
          : []
        const sources = sourcesData.data
        this.playerSources = [...sources]
        player.updateSrc(this.playerSources)
        player.on('resolutionchange', function () {
          console.info('Source changed to %s', player.src())
        })
      }
    },
    toggleZoomVideo () {
      this.zoomVideo = !this.zoomVideo
    }
  },
  destroyed () {
    if (this.activeConversation) {
      this.activeConversation.removeListener(
        'messageAdded',
        this.getUnreadChat
      )
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .custom:hover {
    background-color: #e4e7ec;
  }

  .px {
    padding: 9px 43px;
  }

  .ml-40 {
    margin-left: 40px;
  }

  .container {
    width: 1024px;
  }

  .p-48 {
    padding: 0px 48px 0px 48px;
  }

  .white-s-pre {
    white-space: pre-wrap;
  }

  .icinfo {
    margin-right: 16px;
  }

  .bd-blur-gray {
    border-color: #98a2b3;
  }

  .nav-item.active a {
    color: #20419b !important;
    font-weight: 500;
  }

  .modal-dialog.modal-qr {
    position: relative;
    display: table;
    /* This is important */
    overflow-y: auto;
    overflow-x: auto;
    width: auto;
    min-width: 300px;
  }

  .btn-FEF3F2 {
    background-color: #fef3f2;
    color: #b42318;
    border-color: #fef3f2;
  }

  .btn-add-member {
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 10px;
    border: 1px dashed #20419b;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    /* Primary */

    color: #20419b;
    padding: 8px;
    cursor: pointer;
  }

  .open-chat-att {
    padding: 16px;
    display: flex;
    align-items: center;
    background-color: #fafafa;
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 16px;
    position: relative;
    transition: all 0.3s ease;

    &:hover {
      background-color: #eff4ff;
    }
  }

  .has-unread-chat {
    background-color: #f2f6ff;
    border: 1px solid #20419b;
  }

  .red-dot-new {
    position: absolute;
    top: 5px;
    right: 10px;
  }

  .disabled {
    opacity: 0.6;
    cursor: not-allowed;
    pointer-events: none;
  }

  .loading-btn-icon {
    display: none;
  }

  .loading-btn .loading-btn-icon {
    display: block !important;
  }

  .loading-btn {
    pointer-events: none;
    color: white;
    border-color: #98a2b3 !important;
  }

  .loading-btn span {
    display: none;
  }

  .livestream-redot {
    background-color: #d92d20;
    color: white;
    padding: 1px 8px;
    border-radius: 10px;
    font-size: 10px;
    font-weight: 500;
  }

  .video-player-box {
    position: relative;

    &::before {
      content: "";
      display: block;
      padding-top: percentage(9/16);
    }

    .video-js {
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
    }

    @mixin transition($args...) {
      -webkit-transition: $args;
      -moz-transition: $args;
      -ms-transition: $args;
      -o-transition: $args;
      transition: $args;
    }

    // Sass for videojs-watermark
    .video-js {
      &.vjs-watermark {
        display: block;
      }

      .vjs-watermark-content {
        opacity: 0.99;
        position: absolute;
        padding: 16px 16px 0 0;
        width: 4rem;
        height: 4rem;
        @include transition(visibility 1s, opacity 1s);
      }

      // pre-defined positions
      .vjs-watermark-top-right {
        right: 0;
        top: 0;
      }

      .vjs-watermark-top-left {
        left: 0;
        top: 0;
      }

      // bottom set to 30px to account for controlbar
      .vjs-watermark-bottom-right {
        right: 0;
        bottom: 30px;
      }

      .vjs-watermark-bottom-left {
        left: 0;
        bottom: 30px;
      }

      //fade out when the user is not active and the video is playing.
      &.vjs-user-inactive.vjs-playing .vjs-watermark-fade {
        opacity: 0;
      }
    }
  }
}
</style>
